<template>
  <div :class="['big-box', type == '1' ? 'black' : 'skyblue']">
    <div :class="['top-title', type == '1' ? 'black-color' : 'skyblue-color']">{{ title }}</div>
    <div class="center-box">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '1'
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup() {}
}
</script>

<style lang="less" scoped>
.big-box {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  // overflow: hidden;

  .top-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 48px;
    text-align: center;
  }

  .center-box {
    width: 100%;
    height: calc(100% - 48px);
    // background: skyblue;
    padding: 24px 32px;
  }
}

.skyblue-color {
  color: #1890ff;
  background: rgba(24, 144, 255, 0.1);
}
.black-color {
  color: #969799;
  background: #f5f6f7;
}

.black {
  border: 1px solid #f5f6f7;
}
.skyblue {
  border: 1px solid #d7eafd;
}
</style>
