<template>
  <div class="page-index">
    <div class="left-box" v-if="params.data.oldList?.length">
      <ComparisonBox type="1" :title="params.data.oldTitle">
        <template v-for="item in params.data.oldList" :key="item">
          <RenderingComponent :showObj="item" :type="item.type" />
        </template>
      </ComparisonBox>
    </div>
    <div :class="['right-box', !params.data.oldList?.length && 'fit-box']" v-if="params.data.newList?.length">
      <ComparisonBox type="2" :title="params.data.newTitle">
        <template v-for="item in params.data.newList" :key="item">
          <RenderingComponent :showObj="item" :type="item.type" />
        </template>
      </ComparisonBox>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import ComparisonBox from '../comparisonBox'
import RenderingComponent from '../renderingComponent'

export default {
  props: {
    params: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    ComparisonBox,
    RenderingComponent
  },
  setup() {
    const state = reactive({
      showList: [
        {
          label: '我是左侧',
          value: '我是右侧',
          isChange: true
        },
        {
          label: '我是左侧1111',
          value:
            'v我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧',
          isChange: false
        },
        {
          label: '图标',
          value:
            'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fsafe-img.xhscdn.com%2Fbw1%2F43f2095e-4687-48c3-9763-72205274d247%3FimageView2%2F2%2Fw%2F1080%2Fformat%2Fjpg&refer=http%3A%2F%2Fsafe-img.xhscdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1698309283&t=841d97a94243ec9c0d743fd86631cd11',
          type: 'icon'
        },
        {
          label: '图片',
          value: [
            'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fsafe-img.xhscdn.com%2Fbw1%2F43f2095e-4687-48c3-9763-72205274d247%3FimageView2%2F2%2Fw%2F1080%2Fformat%2Fjpg&refer=http%3A%2F%2Fsafe-img.xhscdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1698309283&t=841d97a94243ec9c0d743fd86631cd11'
          ],
          type: 'image'
        },
        {
          label: '添加电子围栏',
          value: [
            {
              name: '111112311141241441',
              center: '北京',
              distance: '200'
            }
          ],
          type: 'list'
        },
        {
          type: 'authority',
          label: '功能权限',
          value: [
            {
              child: null,
              class: '',
              code: 'PC_INDEX_MENU',
              id: 2,
              leaf: true,
              name: '工作台',
              url: '/dashboard/analysis',
              checked: true
            },
            {
              child: [
                {
                  checked: false,
                  child: null,
                  class: 'disabled',
                  code: 'PC_SEAL_APPLY_MENU',
                  createBy: 1,
                  delFlag: 0,
                  desc: null,
                  icon: '',
                  id: 4,
                  isSystem: 1,
                  leaf: true,
                  name: '用印申请',
                  params: null,
                  parentId: 3,
                  parentName: '用印管理',
                  requestMappingInfo: null,
                  sortIndex: 0,
                  status: 1,
                  type: 1,
                  updateBy: 1,
                  url: '/seal/apply'
                },
                {
                  checked: false,
                  child: null,
                  class: 'disabled',
                  code: 'PC_SEAL_APPLY_MENU',
                  createBy: 1,
                  delFlag: 0,
                  desc: null,
                  icon: '',
                  id: 4,
                  isSystem: 1,
                  leaf: true,
                  name: '用印申请',
                  params: null,
                  parentId: 3,
                  parentName: '用印管理',
                  requestMappingInfo: null,
                  sortIndex: 0,
                  status: 1,
                  type: 1,
                  updateBy: 1,
                  url: '/seal/apply'
                }
              ],
              class: 'disabled',
              code: 'PC_SEAL_MANAGER_MENU',
              id: 3,
              leaf: false,
              name: '用印管理',
              url: '/seal'
            }
          ]
        }
      ],
      showList1: [
        {
          label: '我是左侧',
          value: '我是右侧',
          isChange: false
        },
        {
          label: '我是左侧1111',
          value:
            'v我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧我是右侧',
          isChange: false
        }
      ]
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.page-index {
  margin: 24px;
  padding: 24px;
  background: #ffffff;
  display: flex;

  .left-box {
    // width: calc(50% - 12px);
    flex: 1;
    min-height: calc(100vh - 274px);
    max-width: calc(50% - 12px);
  }

  .right-box {
    flex: 1;
    min-height: 100%;
    margin-left: 24px;
    max-width: calc(50% - 12px);
  }

  .fit-box {
    max-width: 100%;
    margin-left: 0px;
  }
}
</style>
